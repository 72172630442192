import styles from './LoginPage.module.css';
import { FaCheck } from "react-icons/fa";
import BasicInput from '../../components/Reusable/BasicInput';
import { useState } from 'react';
import RegisterPopUp from '../../components/RegisterPopUp';
import ForgotPasswordPopUp from '../../components/ForgotPasswordPopUp';
import PurpleButton from '../../components/Reusable/PurpleButton';

interface ILoginPage{
    user:Object|null;
    setUser:(user:any)=>void
}

const LoginPage:React.FC<ILoginPage> = ({user,setUser}) => {
    const [email,setEmail]=useState("")
    const [password,setPassword]=useState("")
    const [showSignUp,setShowSignUp]=useState(false)
    const [forgotPassword,setForgotPassword]=useState(false)

    const handleRegisterChange=()=>{
        if(!forgotPassword){
            setShowSignUp(prev=>!prev);
        }
    }

    const handleForgotChange=()=>{
        if(!showSignUp){
            setForgotPassword(prev=>!prev)
        }
    }

    const features = [
        "Request up to 2 custom reports per month",
        "API access with up to 10.000 requests per month",
        "Create custom search querys",
        "Create custom graphs directly in SCAS",
        "Search 300+ codebases and 1000+ past audit reports"
    ];




    const handelEmailChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setEmail(e.target.value)
    }
    const handlePasswordChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPassword(e.target.value);
    }

    const handleLogin=()=>{
        setUser({email:"example@example.com","public key":"123"})
    }
    const handleLogout=()=>{
        setUser(null)
    }

    const handleWalletChange=()=>{

    }


    return (
        <div className={styles.payment}>
            <div className={styles.container}>

                {showSignUp && <RegisterPopUp setIsRegisterPopUpVisible={handleRegisterChange}/>}
                {forgotPassword && 
                    <ForgotPasswordPopUp handleForgotChange={handleForgotChange}/>
                }

                {user!==null
                ?<div className={styles.innerContainer}>
                    <div className={styles.userInfoContainer}>
                        {Object.keys(user).map((key,i)=>{
                            return <div>{key} : {Object.values(user)[i]}</div>
                        })}
                    </div>
                    <PurpleButton text="Change wallet" fontSize='10px' onClick={handleWalletChange}/>
                    <PurpleButton text="Log out" onClick={handleLogout}/>
                </div>
                :<div className={styles.innerContainer}>
                    <h3 className={styles.loginText}>Login</h3>
                    <BasicInput title={"Email address"} value={email} placeholder={"xyz@gmail.com"} onChange={handelEmailChange} redBorder={false}/>
                    <BasicInput title={"Password"} value={password} placeholder={"xye"} onChange={handlePasswordChange} redBorder={false} type={"password"}/>   
                    <PurpleButton text="Login" onClick={handleLogin} />
                    <div className={styles.forgot} onClick={handleForgotChange}>Forgot password?</div>
                </div>
                }

                <div className={styles.innerContainer}>
                    <div className={styles.getProContainer}>
                        <h3>Get SCAS Pro</h3>
                        <div className={styles.description}>
                        <p>Get SCAS Pro to access our codebase, access API and more</p>
                        </div>
                        <ul className={styles.featureList}>
                            {features.map((feature) => {return(
                                <li key={feature} className={styles.row}><FaCheck /> {feature}</li>
                            )}
                            )}
                        </ul>
                        <div className={styles.pricing}>
                                <p>- USDC / user / Month </p>
                                <p>- USDC / user / Year </p>
                        </div>
                        <div className={styles.buttonContainer}>
                            <PurpleButton text="Get SCAS Pro" onClick={handleRegisterChange} fontSize='14px'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage