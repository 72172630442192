import styles from './PurpleButton.module.css'

interface IPurpleButton{
    text: string;
    onClick?: () => void;
    fontSize?:string;
    type?: "submit";
}

const PurpleButton:React.FC<IPurpleButton>=({text,onClick,fontSize,type})=>{
    let myType:"button"|"submit"=type?type:"button"

    if(onClick){
        return <button type={myType} style={fontSize?{fontSize:fontSize}:{fontSize:"16px"}} onClick={onClick}className={styles.button}>{text}</button>
    }
    else{
        return <button type={myType} style={fontSize?{fontSize:fontSize}:{fontSize:"16px"}} className={styles.button}>{text}</button>
    }


}

export default PurpleButton