import styles from './Recomendations.module.css'
import { recommendations } from '../../../utils/reportDataTypes';
import React from 'react'
import { MdGppGood } from 'react-icons/md';
interface IRecomendations {
    recommendations : recommendations,
}

const Recomendations = React.forwardRef<HTMLDivElement, IRecomendations>( ({ recommendations }, ref) => {

    if(recommendations.howToAvoidRisk.length < 300){

        return (
            <>
            <div className={styles.container} ref={ref}>
                <h2>Recommendations</h2>
                <div className={styles.InnerContainer}>
                    <div className={styles.Icon}>
                        <MdGppGood size={35}/>
                    </div>
                    <p>{recommendations.howToAvoidRisk}</p>
                </div>
            </div>
            </>
        );
    }else{
        return (
        <>
        <div className={styles.container} ref={ref}>
            <h2>Recommendations</h2>
            <p>{recommendations.howToAvoidRisk}</p>
        </div>
        </>
        );
    }
})

export default Recomendations;