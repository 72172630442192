import styles from './AddAuditCompany.module.css'
import { auditCompaniesType, auditCompanies } from '../../../utils/reportDataTypes';
import { useState } from 'react';
import BasicInput from '../../Reusable/BasicInput';
import axios from 'axios';
import { getAllAuditCompaniesURL, writeAuditCompanieURL } from '../../../api';
import { BasicRadioInput } from '../../Reusable/BasicRadioInput';
import {tmpAuditCompanie} from '../../../utils/templateProtocolData'

export const AddAuditCompany: React.FC = () => {

    const [auditCompany,setAuditCompany] = useState<auditCompanies>(tmpAuditCompanie)

    const sendPublishReportDataRequest = async (request:auditCompanies) => {
        try {
          //let dataIsValid=checkData(request)
          const auditcompany = request;

          console.log(JSON.stringify(auditcompany));

          if(true){
            const {data} = await axios.post(writeAuditCompanieURL, 
                auditcompany,
                {
                  headers: {
                  'Content-Type': 'application/json',
                  }
                }
              );
              console.log(data.message);
          }

        }catch(e){
          console.log(e);
        }
    }

    const handelAuditCompanyNameChange =(e:React.ChangeEvent<HTMLInputElement>)=>{
        let tmpAuditCompany: auditCompanies = auditCompany;
        tmpAuditCompany.name=e.target.value;
        setAuditCompany({...tmpAuditCompany});
    }

    const handelAuditCompanyURLChange =(e:React.ChangeEvent<HTMLInputElement>)=>{
        let tmpAuditCompany: auditCompanies = auditCompany;
        tmpAuditCompany.link=e.target.value;
        setAuditCompany({...tmpAuditCompany});
    }

    const handelAuditCompanyLogoChange =(e:React.ChangeEvent<HTMLInputElement>)=>{
        let tmpAuditCompany: auditCompanies = auditCompany;
        tmpAuditCompany.logoURL=e.target.value;
        setAuditCompany({...tmpAuditCompany});
    }

    const handelAuditCompanyRadioChange =(e:React.ChangeEvent<HTMLInputElement>)=>{
        let tmpAuditCompany: auditCompanies = auditCompany;
        tmpAuditCompany.type=auditCompaniesType[e.target.value as keyof typeof auditCompaniesType];
        console.log(e.target.value);
        setAuditCompany({...tmpAuditCompany});
    }
    
    const sendGetAllAuditCompaniesRequest = async () => {
        try {
            const {data} = await axios.post(getAllAuditCompaniesURL, 
                "{}",
                {
                    headers: {
                    'Content-Type': 'application/json',
                    }
                }
            );
            const products : auditCompanies[] = data;
            if(products[0].name === "Sherlock"){
                console.log("Corrent!");
            }else{
                console.log("Error, request did not return right data");
                console.log(products);
            }
            
        }catch(e){
            console.log(e);
        }
    }

    const sendSpam = async () => {
        for(var i = 0; i< 100; i++){
            sendGetAllAuditCompaniesRequest();
            sendGetAllAuditCompaniesRequest();
        }
    }

    return(
        <>
    <div className={styles.container}>
        <span>Add Audit Company</span>
        <BasicInput title={"Name"} placeholder={"Sherlock"} onChange={handelAuditCompanyNameChange} redBorder={false}/>
        <BasicInput title={"Link"} placeholder={"https://"} onChange={handelAuditCompanyURLChange} redBorder={false}/>
        <BasicInput title={"logoURL"} placeholder='https://' onChange={handelAuditCompanyLogoChange} redBorder={false}/>

    <BasicRadioInput value={auditCompany.type} buttons={[
        {type:auditCompaniesType.ContestPlatform,title:"Contest platform",value:"ContestPlatform"},
        {type:auditCompaniesType.Institutional,value:"Institutional",title:"Institutional"},
        {type:auditCompaniesType.IndependentResearcher,value:"IndependentResearcher",title:"Independent Researcher"}
        ]} onChange={handelAuditCompanyRadioChange}/>
    <button className={styles.saveButton} onClick={() => sendPublishReportDataRequest(auditCompany)} >Save audit company</button>
    <span>Spam backend with requests</span>
    <button className={styles.saveButton} onClick={() => sendSpam()}>SPAM</button>
    </div>
    </>
    )
}
