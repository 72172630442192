
import styles from './DarkLightModeButton.module.css'
import { IoMoonSharp,IoSunnyOutline } from "react-icons/io5";

interface IDarkLightModeButton{
    isDark:boolean;
    setIsDark:(isDark:boolean)=>void
}

export const DarkLightModeButton:React.FC<IDarkLightModeButton>  = ({isDark,setIsDark}) =>{
    const handleChange=()=>{
        if(isDark){
            localStorage.setItem('theme', 'light');
        }
        else{
            localStorage.setItem('theme', 'dark');
        }
        setIsDark(!isDark)
    }

    return(
        <button className={styles.button} onClick={handleChange}>
            <div className={styles.innerBox}>
                {isDark ? <IoMoonSharp/> : <IoSunnyOutline/>}
            </div>
        </button>
    )

}
