
import styles from './Centralization.module.css';
import { centralization, protocolData } from '../../../utils/reportDataTypes';
import { useState } from 'react';
import { isNumber } from '../../../utils/adminUtils';
import BasicInput from '../../Reusable/BasicInput';
import CheckedInput from '../../Reusable/CheckedInput';

interface ICentralization{
    fullObj:protocolData;
    setOBJ: (newObj: any) => void;
    setCentralizationIsValid:(centralizationIsValid:boolean)=>void;

}

const Centralization: React.FC<ICentralization> = ({fullObj, setOBJ,setCentralizationIsValid }) => {
    const obj:centralization=fullObj.centralization
    const [scoreIsValid, setScoreIsValid] = useState<boolean>(true)

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        obj.upgradable=e.target.checked
        setOBJ({...fullObj});
    }

    const handleCentralizationScoreChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        let num = Number(e.target.value)
        if(isNumber(num)){
            if(num>=0 && num<=20){
                setScoreIsValid(true)
                setCentralizationIsValid(true)
            }
            else{
                setScoreIsValid(false)
                setCentralizationIsValid(false)
            }
            obj.centralizationScore=num
        }
        setOBJ({...fullObj})
    }
    //Change!!!
    const handleTimeLockChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        obj.timelocksUsed=false
        setOBJ({...fullObj})
    }

    const handleAdminWalletChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        obj.adminWallet=e.target.value
        setOBJ({...fullObj})
    }
    //Change!!!
    const handlePausableChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        obj.pausable=false
        setOBJ({...fullObj})
    }

    const handleFrontEndDeploymentChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        obj.frontendDeployment=e.target.value
        setOBJ({...fullObj})
    }    

    const handlePossiblityOfOwnFrontendChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        obj.possiblityOfOwnFrontend=e.target.value
        setOBJ({...fullObj})
    }

    return(
        <div className={styles.container}>
           <span>Centralization</span>
           <BasicInput title="Centralization score" value={obj.centralizationScore} redBorder={!scoreIsValid} placeholder={"15"} onChange={handleCentralizationScoreChange}/>
           <CheckedInput title="Upgradable" checked={obj.upgradable} onChange={handleCheck}/> 
           <BasicInput title="Timelocks used" value={"obj.timelocksUsed"} redBorder={false} placeholder="No timelocks" onChange={handleTimeLockChange}/>
           <BasicInput title="Admin wallet" value={obj.adminWallet} redBorder={false} placeholder="No admin wallets" onChange={handleAdminWalletChange}/>
           <BasicInput title="Pausable" value={"obj.pausable"} redBorder={false} placeholder="Not pausable" onChange={handlePausableChange}/>
           <BasicInput title="Frontend deployment" value={obj.frontendDeployment} redBorder={false} placeholder="" onChange={handleFrontEndDeploymentChange}/>
           <BasicInput title="Possiblity of own frontend" value={obj.possiblityOfOwnFrontend} redBorder={false} placeholder="Front end is possible" onChange={handlePossiblityOfOwnFrontendChange}/>
        </div>
    )
}

export default Centralization