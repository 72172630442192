import styles from './FeaturedAuditCompany.module.css';
import { auditCompanies, auditCompaniesType } from "../../utils/reportDataTypes";
import { getCompanyByIdArrayURL } from "../../api";
import axios from "axios";
import { useEffect, useState } from "react";
import BasicToolTip from "../Reusable/BasicToolTip";
import { IoShieldCheckmarkOutline } from "react-icons/io5";

const FeaturedAuditCompany = () => {
    const [featuredCompany,setFeaturedCompany] = useState<auditCompanies>({name:"tmp",logoURL:"/SCASLogo.png",link:"",type: auditCompaniesType.ContestPlatform,id:100});
    
    useEffect(() => {
        const fetchFeaturedAuditCompany = async () => {
            await sendGetFeaturedCompanyRequest(2);
        }
        fetchFeaturedAuditCompany();
    },[]);

    const sendGetFeaturedCompanyRequest = async (id:number) => {
        const sendData = [id];
        try {
            const {data} = await axios.post(getCompanyByIdArrayURL, 
                sendData,
                {
                    headers: {
                    'Content-Type': 'application/json',
                    }
                }
            );
            const company : auditCompanies = data[0];
            if(company !== undefined){
                setFeaturedCompany(company);
            }
        }catch(e){
            console.log(e);
        }
    }

    return (
        <div className={styles.featureBox}>
            <h3>Featured Audit Company </h3>
            <div className={styles.featuredBoxInner}>
                <div className={styles.headerRow}>
                    <img alt='' src={featuredCompany.logoURL} className={styles.image} />
                    <div className={styles.headerText}>
                        <h4>{featuredCompany.name} 
                            <BasicToolTip icon={<IoShieldCheckmarkOutline size={20}/>} text={(<p>SCAS Verified</p>)}/>
                        </h4>
                        <p>The Final Exam Audit Before Mainnet</p>
                    </div>
                </div>
                <div className={styles.companyTypeRow}>
                    <h4>{featuredCompany.name} Security Contests</h4>
                    <p>
                        Let cybersecurity professionals compete to protect your users from the most dangerous vulnerabilities. 
                    </p>
                </div>
                <div className={styles.trustedBy}>
                    <h4>Trusted by</h4>
                    <div className={styles.companyBox}>
                        <img alt='' src={"https://pbs.twimg.com/profile_images/1327058875627970561/zk8nf4kv_400x400.jpg"} className={styles.image} />
                        <p>Notional</p>
                    </div>
                    <div className={styles.companyBox}>
                        <img alt='' src={"https://pbs.twimg.com/profile_images/1734354549496836096/-laoU9C9_400x400.jpg"} className={styles.image} />
                        <p>Optimism</p>
                    </div>
                </div>
                <a href={featuredCompany.link} className={styles.quoteButton}>Get a quote</a> 
            </div>
        </div>
    );
}
export default FeaturedAuditCompany;