import styles from './SecInfra.module.css'
import { secInfra, protocolData,auditCompanies } from '../../../utils/reportDataTypes';
import {isNumber,checkIfValid} from '../../../utils/adminUtils';
import { useState,useEffect } from 'react';
import BasicInput from '../../Reusable/BasicInput';
import BasicList from '../../Reusable/BasicList';
import axios from 'axios'
import { getAllAuditCompaniesURL } from "../../../api";

interface IPastAudtis{
    fullObj: protocolData,
    setOBJ: (newObj: any) => void;
    setSecInfraIsValid:(pastAuditsIsValid:boolean)=>void;

}

export const SecInfra: React.FC<IPastAudtis> = ({ fullObj, setOBJ ,setSecInfraIsValid}) => {

    const obj:secInfra=fullObj.secInfra
    const [logoURLiSValid,setLogoURLiSValid]=useState<Array<boolean>>([])
    const [linkIsValid,setLinkIsValid]=useState<Array<boolean>>([])
    const [allAuditCompanies,setAllAuditCompanies]=useState<auditCompanies[]>([])

    useEffect(() => {
        const sendGetProductsRequest = async () => {
            try {
                const { data } = await axios.post(getAllAuditCompaniesURL, 
                    "{}",
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                const products = data;
                setAllAuditCompanies(products);
                console.log("Request was correct");
            } catch (e) {
                console.log(e);
                // Use this in future for feedback in frontend!
                // setMessage({ message: "No Connection to server" });
            }
        }

        sendGetProductsRequest();
    }, []); 


    const handleSecIncidentChange= ( index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        //obj.recentSecIncidents[index]=e.target.value
        setOBJ({...fullObj})
    }

    const handleAddAuditCompany=()=>{
        linkIsValid.push(false)
        logoURLiSValid.push(false)
        setLinkIsValid(linkIsValid)
        setLogoURLiSValid(logoURLiSValid)
        setSecInfraIsValid(false)

        obj.auditedBy.push(0)
        setOBJ({...fullObj})
    }

    const handleAddAuditTime=()=>{
        obj.timeOfAudits.push("")
        setOBJ({...fullObj})
    }

    const handleAddrecentSecIncident=()=>{
        obj.recentSecIncidents.push()
        setOBJ({...fullObj})
    }

    const handleRemoveTime = (i: number) => {
        delete obj.timeOfAudits[i]
        setOBJ({...fullObj})
    };

    const handleRemoveSecIncident = (i: number) => {
        delete obj.recentSecIncidents[i]
        setOBJ({...fullObj})
    };

    const handleRemoveAuditCompany= (i: number) => {
        delete linkIsValid[i]
        delete logoURLiSValid[i]
        setLinkIsValid(linkIsValid)
        setLogoURLiSValid(logoURLiSValid)
        setSecInfraIsValid(checkIfValid([linkIsValid,logoURLiSValid]))

        delete obj.auditedBy[i]
        setOBJ({...fullObj})
    };

    const handleBugBountyChange =(e:React.ChangeEvent<HTMLInputElement>)=>{
        obj.bugBounty=e.target.value
        setOBJ({...fullObj})
    }

    const handleBugBountyAmountChange =(e:React.ChangeEvent<HTMLInputElement>)=>{
        let num = Number(e.target.value)
        if(isNumber(num)){
            obj.bugBountyAmount=num
            setOBJ({...fullObj})
        }
    }

    const handlePaidWhiteHatBefore  =(e:React.ChangeEvent<HTMLInputElement>)=>{
        obj.paidWhiteHatBefore=e.target.value
        setOBJ({...fullObj})
    }

    const handleTimeOfAuditChange = ( index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        obj.timeOfAudits[index]=e.target.value
        setOBJ({...fullObj})
    }

    const handleSelectChange=(index:number)=>(event:React.ChangeEvent<HTMLSelectElement>)=>{
        obj.auditedBy[index]=Number(event.target.value)
        setOBJ({...fullObj})
    }
    return(
        <>
    <div className={styles.container}>
        <span>Security Infrastructure</span>
{/*         <select value={selectedAuditCompanyId} onChange={handleSelectChange}>
                {allAuditCompanies.map((option,i) => (
                    <option key={i} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select> */}

        <BasicInput value={obj.bugBounty} title={"Bug bounty"} placeholder={""} onChange={handleBugBountyChange} redBorder={false}/>
        <BasicInput value={obj.bugBountyAmount} title={"Bug bounty amount"} placeholder={"15"} onChange={handleBugBountyAmountChange} redBorder={false}/>
        <BasicInput value={obj.paidWhiteHatBefore} title={"Paid whitehat before"} placeholder='Paid whitehats when...' onChange={handlePaidWhiteHatBefore} redBorder={false}/>
        <BasicList gap={10} title="Time of audits" handleRemove={handleRemoveTime} handleAdd={handleAddAuditTime} height={170} list={obj.timeOfAudits}
           items={[{type:"input",property:"",onChange:handleTimeOfAuditChange,title:"",placeholder:"23.12.2023",borderIsValid:[] }]}/>

        <BasicList gap={10} title="Recent Sec Incidents" handleRemove={handleRemoveSecIncident} handleAdd={handleAddrecentSecIncident} height={220} list={obj.recentSecIncidents}
                items={[{type:"textarea",property:"",onChange:handleSecIncidentChange,title:"",placeholder:"",borderIsValid:[] }]}/>
    
        <BasicList gap={40} title="Audited by" handleRemove={handleRemoveAuditCompany} handleAdd={handleAddAuditCompany} height={400} list={obj.auditedBy}
            items={[
                {type:"select",onChange:handleSelectChange,title:"",value:"id",display:"name",options:allAuditCompanies },
                ]}/>
        </div>
    </>
    )
}
