export const paymentURL = "https://api.scauditstudio-api.de/register";
export const productURL = "https://api.scauditstudio-api.de/products";
export const writeReportDataURL = "https://api.scauditstudio-api.de/publish";
export const getReportDataURL = "https://api.scauditstudio-api.de/get-report";
export const getReportPreviewsURL = "https://api.scauditstudio-api.de/get-report-thumbs";
export const getAllAuditCompaniesURL = "https://api.scauditstudio-api.de/get-all-companies";
export const writeAuditCompanieURL = "https://api.scauditstudio-api.de/add-audit-company";
export const getCompanyByIDURL = "https://api.scauditstudio-api.de/get-company";
export const getCompanyByIdArrayURL = "https://api.scauditstudio-api.de/get-companies";

export const getRequestCustomReportURL = "https://forms.gle/mH9pF82faBKhmCUy6";
export const reportFeedbackURL = "https://forms.gle/dtvPsckAiwUx622S7";

export const centralizationDocsURL = "https://scas.gitbook.io/scas-metrics/centralization-score";
export const ratingDocsURL = "https://scas.gitbook.io/scas-metrics";
export const otherDocsURL = "https://scas.gitbook.io/scas-metrics/other-scores";