
import { useState } from 'react';
import { protocolData,recommendations } from '../../../utils/reportDataTypes';
import styles from './BasicInfo.module.css';
import {isValidURL,isNumber,checkIfValid} from  '../../../utils/adminUtils'
import BasicInput from '../../Reusable/BasicInput';
import BasicTextArea from '../../Reusable/BasicTextArea';
import BasicList from '../../Reusable/BasicList';

export type basicInfo={
    name: string;
    score: number;
    codeBaseLink: string;
    recommendations:recommendations;
    socialLinks: string[];
    logoURL:string;
}

interface IBasicInfo{
    fullObj: protocolData,
    setOBJ: (newObj: any) => void;
    setBasicInfoIsValid:(basicInfoIsValid:boolean)=>void;
}

export const BasicInfo: React.FC<IBasicInfo> = ({ fullObj, setOBJ,setBasicInfoIsValid }) => {
    const [scoreIsValid, setScoreIsValid] = useState<boolean>(true)
    const [codeBaseURLIsValid, setCodeBaseURLIsValid] = useState<boolean>(false)
    const [logoUrlIsValid, setLogoUrlIsValid] = useState<boolean>(false)
    const [socialLinkIsValid, setSocialLinkIsValid] = useState<Array<boolean>>([])

    const handleAddSocial=()=>{
        setSocialLinkIsValid(prev=>prev.concat([false]))
        setBasicInfoIsValid(false)
        const updatedObj = {
            ...fullObj,
            socialLinks: fullObj.socialLinks.concat([""])
        };
        setOBJ(updatedObj)
    }

    const handleSocialChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {

        if(isValidURL(e.target.value)){
            socialLinkIsValid[index]=true
        }
        else{
            socialLinkIsValid[index]=false
        }
        setSocialLinkIsValid(socialLinkIsValid)
        setBasicInfoIsValid(checkIfValid([scoreIsValid,codeBaseURLIsValid,socialLinkIsValid]))

        fullObj.socialLinks[index]=e.target.value
        setOBJ({...fullObj})
    }


    const handleRemoveSocial = (index: number) => {
        setSocialLinkIsValid(prev=>prev.filter((_,i) => i !== index ))
        delete fullObj.socialLinks[index]
        setBasicInfoIsValid(checkIfValid([scoreIsValid,codeBaseURLIsValid,socialLinkIsValid]))
        setOBJ({...fullObj});
    }


    const handleNameChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        let copy={...fullObj}
        copy.name=e.target.value
        setOBJ(copy)
    }

    const handleScoreChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        let copy={...fullObj}
        let num=Number(e.target.value)
        if(isNumber(num)){
            if(num>=0 && num<=100 ){
                setScoreIsValid(true)
            }
            else{
                setScoreIsValid(false)
            }
            setBasicInfoIsValid(checkIfValid([scoreIsValid,codeBaseURLIsValid,socialLinkIsValid]))
            copy.score=num
        }
        setOBJ(copy)
    }

    const handleCodebaseLinkChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        if(isValidURL(e.target.value)){
            setCodeBaseURLIsValid(true)
        }
        else{
            setCodeBaseURLIsValid(false)
        }
        setBasicInfoIsValid(checkIfValid([scoreIsValid,codeBaseURLIsValid,socialLinkIsValid]))

        let copy={...fullObj}
        copy.codeBaseLink=e.target.value
        setOBJ(copy)
    }
    
    const handleLogoUrlChange=(e:React.ChangeEvent<HTMLInputElement>)=>{

        if(isValidURL(e.target.value)){
            setLogoUrlIsValid(true)
        }
        else{
            setLogoUrlIsValid(false)
        }
        setBasicInfoIsValid(checkIfValid([scoreIsValid,codeBaseURLIsValid,socialLinkIsValid]))

        let copy={...fullObj}
        copy.logoURL=e.target.value
        setOBJ(copy)
    }

    const handleRecommendationsChange=(e:React.ChangeEvent<HTMLTextAreaElement>)=>{
        let copy={...fullObj}
        copy.recommendations.howToAvoidRisk=e.target.value
        setOBJ(copy)
    }


    return (
        <div className={styles.container}>
           <span>Basic Info</span>
           <BasicInput title={"Name"} value={fullObj.name} placeholder={"Uniswap"} onChange={handleNameChange} redBorder={false}/>
           <BasicInput title={"Score"} value={fullObj.score} placeholder={""} onChange={handleScoreChange} redBorder={scoreIsValid }/>
           <BasicInput title={"Codebase link"} value={fullObj.codeBaseLink} placeholder={"www."} onChange={handleCodebaseLinkChange} redBorder={!codeBaseURLIsValid }/>
           <BasicInput title={"Logo Url"} value={fullObj.logoURL} placeholder={"www."} onChange={handleLogoUrlChange} redBorder={!logoUrlIsValid}/>
           <BasicTextArea title={"Recommendations"} value={fullObj.recommendations.howToAvoidRisk} placeholder={"try to .."} onChange={handleRecommendationsChange} redBorder={false}/>

           <BasicList gap={10} title="Social links" handleRemove={handleRemoveSocial} handleAdd={handleAddSocial} height={170} list={fullObj.socialLinks}
           items={[{type:"input",property:"",onChange:handleSocialChange,title:"",placeholder:"www.",borderIsValid:socialLinkIsValid }]}/>


        </div>
    );

}

export default BasicInfo