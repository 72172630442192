import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { darkTheme } from "../../../../../utils/theme";
ChartJS.register(ArcElement, Tooltip, Legend);

interface IDoughnutChart {
  highIssues:number,
  mediumIssues:number,
  lowIssues:number,
  informationalIssues:number,
  currentTheme?: typeof darkTheme,
}

export const DoughnutChart:React.FC<IDoughnutChart> = ({highIssues,mediumIssues,lowIssues,informationalIssues,currentTheme}) => {
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        fullSize: true,
        maxHeight: 55,
        maxWidth: 100,
        labels:{
          padding: 5,
          color: currentTheme?.text,
        }
      },
      title: {
        display: true,
        text: 'Issues by severity',
        color: currentTheme?.text,
      },
    },
  }
  const data = {
    labels: ['High', 'Medium', 'Low', 'Informational'],
    datasets: [
      {
        label: '# of Issues',
        data: [highIssues,mediumIssues,lowIssues,informationalIssues],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(68, 68, 68, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(68, 68, 68, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
    return <Doughnut options={chartOptions} data={data} />;
  }