import styles from './ProtocolPage.module.css';
import InfoHeader from '../../components/ProtocolPageComponents/InfoHeader';
import SideMenu from '../../components/ProtocolPageComponents/SideMenu';
import SecInfra from '../../components/ProtocolPageComponents/SecInfra';
import Pastaudits from '../../components/ProtocolPageComponents/PastAudits';
import CodeComplexity from '../../components/ProtocolPageComponents/CodeComplexity';
import Centralization from '../../components/ProtocolPageComponents/Centralization';
import CrossProtocolExposure from '../../components/ProtocolPageComponents/CrossProtocolExposure';
import Recomendations from '../../components/ProtocolPageComponents/Recomendations';

//Only for dev rn, will be replaced by API call
import { tmpProtocolReport } from '../../utils/templateProtocolData';

import { useRef } from 'react';
import Disclaimer from '../../components/ProtocolPageComponents/Disclaimer';
import { darkTheme } from '../../utils/theme';

interface IProtocolPage {
    currentTheme?: typeof darkTheme,
}

const ProtocolPage:React.FC<IProtocolPage> = ({currentTheme}) => {
    const secInfraRef = useRef(null);
    const PastauditsRef = useRef(null);
    const CodeComplexityRef = useRef(null);
    const CentralizationRef = useRef(null);
    const CrossProtocolExposureRef = useRef(null);
    const RecomendationsRef = useRef(null);
    return (
        <div className="about">
            <div className={styles.container}>
                
                <div className={styles.contentContainer}>
                    <SideMenu refs={[secInfraRef,PastauditsRef,CodeComplexityRef,CentralizationRef,CrossProtocolExposureRef,RecomendationsRef]}/>
                    <div className={styles.protocolContent}>
                        <InfoHeader protocolData={tmpProtocolReport}/>
                        <SecInfra protocolData={tmpProtocolReport} ref={secInfraRef}/>
                        <Pastaudits currentTheme={currentTheme} pastAudits={tmpProtocolReport.pastAudits} ref={PastauditsRef}/>
                        <CodeComplexity currentTheme={currentTheme} codeComplexity={tmpProtocolReport.codeComplexity} ref={CodeComplexityRef}/>
                        <Centralization protocolData={tmpProtocolReport} ref={CentralizationRef}/>
                        <CrossProtocolExposure crossProtocolExposure={tmpProtocolReport.crossProtocolExposure} ref={CrossProtocolExposureRef}/>
                        <Recomendations recommendations={tmpProtocolReport.recommendations} ref={RecomendationsRef}/>
                        <Disclaimer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProtocolPage