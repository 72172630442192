import { RxCross1 } from 'react-icons/rx'
import styles from './ForgotPasswordPopUp.module.css'
import { useState } from 'react'

interface IForgotPasswordPopUp{
    handleForgotChange:()=>void
}

const success=(email:string)=>{
    return `An email has been sent to ${email}`
}


const ForgotPasswordPopUp:React.FC<IForgotPasswordPopUp>=({handleForgotChange})=>{
    const [email,setEmail]=useState<string>("")
    const [emailSent,setEmailSent]=useState<string>("")
    const [emailNotFound,setEmailNotFound]=useState<boolean>(false)

    const handleEmailChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setEmailNotFound(false)
        setEmail(e.target.value)
    }

    function handleChangePassWordEmail():boolean{
        return false
    }

    const handleSubmit=(e:React.FormEvent)=>{
        e.preventDefault()
        let res=handleChangePassWordEmail()
        if(res){
            setEmailSent(success(email))
        }
        else{
            setEmailNotFound(true)
        }
    }

    return(
        <div className={styles.forgotWindow}>
            <RxCross1 className={styles.exit}  onClick={handleForgotChange}/>
            {emailSent
                ?<div>{emailSent}</div>
                :<div className={styles.forgotContainer}>
                    <div>Enter email:</div>
                    <form onSubmit={handleSubmit}>
                        <input value={email} onChange={handleEmailChange}/>
                        {emailNotFound && <div className={styles.emailError}>Email not Found</div>}
                        <button type="submit">send</button>
                    </form>
                </div>
            }
        </div>
    )
}

export default ForgotPasswordPopUp