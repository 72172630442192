import styles from './PastAudits.module.css'
import { pastAudits } from '../../../utils/reportDataTypes';
import {  useEffect, useState } from 'react';
import { BarChart } from './Charts/BarChart';
import { DoughnutChart } from './Charts/DoughnutChart';
import React from 'react';
import { FaFileCode } from 'react-icons/fa';
import ShowMoreButton from '../../Reusable/ShowMoreButton';
import { darkTheme } from '../../../utils/theme';

interface IPastaudits {
    pastAudits : pastAudits,
    currentTheme?: typeof darkTheme,
}

function getSeverityColor(severity:string){
    if(severity === "high"){
        return "rgba(255, 99, 132, 0.2)"
    }
    else if(severity === "medium"){
        return "rgba(255, 159, 64, 0.2)"
    }
    else{
        return "#FECE56"
    }
}
function getSeverityBorderColor(severity:string){
    if(severity === "high"){
        return "rgba(255, 99, 132, 1)"
    }
    else if(severity === "medium"){
        return "rgba(255, 159, 64, 1)"
    }
    else{
        return "#FECE56"
    }
}

const Pastaudits=React.forwardRef<HTMLDivElement, IPastaudits>( ({ pastAudits,currentTheme }, ref) => {

    const [amountOfHighVulPerFile, setAmountOfHighVulPerFile] = useState<number[]>([]);
    const [amountOfMediumVulPerFile, setAmountOfMediumVulPerFile] = useState<number[]>([]);
    const [highIssues, setHighIssues] = useState(0);
    const [mediumIssues, setMediumIssues] = useState(0);
    const [lowIssues, setLowIssues] = useState(0);
    const [informationalIssues, setInformationalIssues] = useState(0);
    const [filesAffected, setFilesAffected] = useState<string[]>([]);

    const [showAllIssues,setShowAllIssues] = useState(false);
    const [showCodeLocations,setShowCodeLocations] = useState(false);

    useEffect(() => {
        const arr:string[] = [];
        
        for(let i = 0; i < pastAudits.reports.length; i++){
            if(!arr.includes(pastAudits.reports[i].file)){
                arr.push(pastAudits.reports[i].file);
            }
        }

        const amountOfHighVulPerFile:number[] = new Array<number>(arr.length);
        const amountOfMediumVulPerFile:number[] = new Array<number>(arr.length);
        let tmpMedIssue = 0;
        let tmpHighIssue = 0;
        let tmpLowIssue = 0;
        let tmpInfoIssue = 0;
        //This might not be the optimal solution
        for(let i = 0; i < pastAudits.reports.length; i++){
            if(pastAudits.reports[i].severity === "medium"){
                if(amountOfMediumVulPerFile[arr.indexOf(pastAudits.reports[i].file)] > 0){
                    amountOfMediumVulPerFile[arr.indexOf(pastAudits.reports[i].file)]++;
                }else{
                    amountOfMediumVulPerFile[arr.indexOf(pastAudits.reports[i].file)] = 1;
                }
                tmpMedIssue++;
            }else if(pastAudits.reports[i].severity === "high"){
                if(amountOfHighVulPerFile[arr.indexOf(pastAudits.reports[i].file)] > 0){
                    amountOfHighVulPerFile[arr.indexOf(pastAudits.reports[i].file)]++;
                }else{
                    amountOfHighVulPerFile[arr.indexOf(pastAudits.reports[i].file)] = 1;
                }
                tmpHighIssue++;
            }
            if(pastAudits.reports[i].severity === "low"){
                tmpLowIssue++;
            }
            if(pastAudits.reports[i].severity === "info"){
                tmpInfoIssue++;
            }
        }

        setHighIssues(tmpHighIssue);
        setMediumIssues(tmpMedIssue);
        setLowIssues(tmpLowIssue);
        setInformationalIssues(tmpInfoIssue);
        
        setAmountOfHighVulPerFile(amountOfHighVulPerFile);
        setAmountOfMediumVulPerFile(amountOfMediumVulPerFile);
        setFilesAffected(arr);
    },[pastAudits.reports]);

    return (
    <>
    <div id="pastAuditContainer" className={styles.container} ref={ref}>
        <h2>Past Audits</h2>
        <div className={styles.InnerContainer}>
                <div className={styles.chartContainer}>
                        <DoughnutChart currentTheme={currentTheme} highIssues={highIssues} mediumIssues={mediumIssues} lowIssues={lowIssues} informationalIssues={informationalIssues}/>
                </div>
                <div style={{display:"flex",flexDirection:"column",width:"100%"}}>

                <div className={styles.InfoContainer}>
                    <div className={styles.InfoContainerBox}>
                        <p>Number of Audits </p>
                        <h2>{pastAudits.auditAmount}</h2>
                    </div>
                    <div className={styles.InfoContainerBox}>
                        <p>Number of Vul. Found </p>
                        <h2>{pastAudits.reportAmount}</h2>
                    </div>
                    <div className={styles.InfoContainerBox}>
                        <p>Date of Last Audit </p>
                        <h2>{pastAudits.audits[0].date}</h2>
                    </div>
                </div>
                
                {pastAudits.audits.length>0 &&
                <div className={styles.vulReportContainer}>
                    <h3>Past Audit Reports</h3>
                    <p>Last codebase change was on: {pastAudits.lastCodeBaseModification}</p>
                    <ul className={styles.vulReportList}>
                        <li className={styles.row}>
                            <div className={styles.vulInfo}> Company </div>
                            <div className={styles.vulLevelHeader}> Date </div>
                            <div className={styles.vulInfo}> Number of Vul Found </div>
                            <div className={styles.linkIcon}> Link </div>
                        </li>
                        {pastAudits.audits.map((audit,i)=>{
                            return( 
                                <li key={i} style={i!==pastAudits.audits.length-1? {borderBottom:"1px solid var(--ProtocolPage-border-color)",display:"flex"}:{display:"flex"}}>
                                    <div className={styles.vulInfo}>{audit.company}</div>
                                    <div className={styles.vulLevelHeader}>{audit.date}</div>
                                    <div className={styles.vulInfo}>{audit.reportAmount}</div>
                                    <div className={styles.linkIcon}> <a href={audit.link}>View Report <FaFileCode/></a></div>
                                </li>
                                )
                        })}
                    </ul>
                </div>
                }

                </div>
        </div>
        <ShowMoreButton onClick={() => setShowCodeLocations(!showCodeLocations)} text={"Show Code Locations"} showMore={showCodeLocations} />
        {showCodeLocations &&
            <div className={styles.chartContainerFull}>
                <BarChart currentTheme={currentTheme} labels={filesAffected} AmountOfHighVulPerFile={amountOfHighVulPerFile} AmountOfMediumVulPerFile={amountOfMediumVulPerFile}/>
            </div>
        }
        <ShowMoreButton onClick={() => setShowAllIssues(!showAllIssues)} text={"Show all Vulnerabilities"} showMore={showAllIssues} />
        {pastAudits.reports.length>0 && showAllIssues &&
                <div className={styles.vulReportContainer}>
                    <h3>Vulnerabilities reported in past audits</h3>
                    <ul className={styles.vulReportList}>
                        <li className={styles.row}>
                            <div className={styles.vulLevelHeader}> Severity </div>
                            <div className={styles.vulInfo}> Type </div>
                            <div className={styles.vulInfo}> Location </div>
                            <div className={styles.linkIcon}> Link </div>
                        </li>
                        {pastAudits.reports.map((audit,i)=>{
                            return( 
                                <li key={i} style={i!==pastAudits.reports.length-1? {borderBottom:"1px solid var(--ProtocolPage-border-color)",display:"flex"}:{display:"flex"}}>
                                    <div style={{background:getSeverityColor(audit.severity),borderColor:getSeverityBorderColor(audit.severity)}} className={styles.vulLevel}>{audit.severity}</div>
                                    <div className={styles.vulInfo}>{audit.issueType} vulnerability </div>
                                    <div className={styles.vulInfo}>{audit.file}</div>
                                    <div className={styles.linkIcon}> <a href={audit.link}>View Report <FaFileCode/></a></div>
                                </li>
                                )
                        })}
                    </ul>
                </div>
        }
    </div>
    </>
    );
})

export default Pastaudits;