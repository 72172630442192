import React from 'react';
import styles from './RegisterPopUp.module.css';
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PurpleButton from '../Reusable/PurpleButton';

function containsUpperCase(str:string) {
    return /[A-Z]/.test(str);
  }

function isPasswordValid(inputString: string) {
    return !inputString.includes(" ") && inputString.length>7 && containsUpperCase(inputString)
}


interface IRegisterPopUp {
    setIsRegisterPopUpVisible: (bool: boolean) => void;
}

function isValidEmail(email:string) {
    var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
}

const RegisterPopUp: React.FC<IRegisterPopUp> = ({setIsRegisterPopUpVisible}) => {
    const [email,setEmail]=useState<string>("")
    const [password,setPassword]=useState<string>("")
    const [password2,setPassword2]=useState<string>("")
    const [emailError,setEmailError]=useState<boolean>(false)
    const [passwordError,setPasswordError]=useState<boolean>(false)
    const [passwordMatchError,setPasswordMatchError]=useState<boolean>(false)

    const [isRegistered,setIsRegistered]=useState<boolean>(false)
    const [registerPending,setRegisterPending]=useState<boolean>(true)
    
    async function connectWallet() {

      }


    const handleEmailChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setEmailError(!isValidEmail(e.target.value))
        setEmail(e.target.value)
    }

    const handlePasswordChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPasswordMatchError(e.target.value !== password2)
        setPasswordError(!isPasswordValid(e.target.value))
        setPassword(e.target.value)
    }

    const handlePassword2Change=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPasswordMatchError(e.target.value !== password)
        setPassword2(e.target.value)
    }

    const handleSubmit=(e:React.FormEvent)=>{
        e.preventDefault()
        if(password !== "" && password2 !== "" && !passwordError && email!=="" && !emailError && !passwordMatchError){
            setIsRegistered(true)
            setTimeout(()=>{
                setRegisterPending(false)
            },3000)
        }
    }

    if (isRegistered){
        if(registerPending){
            return(
                <div className={styles.container}>
                    <Box sx={{ display: 'flex' ,alignItems:"center",justifyContent:"center",height:"100%",width:"100%"}} >
                        <CircularProgress className={styles.circle}/>
                    </Box>
                </div>)
        }
        return(
            <div className={styles.container}>
                <RxCross1 className={styles.exit}  onClick={()=>setIsRegisterPopUpVisible(false)}/>
                <div style={{ display: 'flex' ,alignItems:"center",justifyContent:"center",height:"90%",width:"80%",textAlign:"center"}}>
                    <div>Confirm your email with the link sent to {email}</div>
                </div>
            </div>
        )

        
    }
    return (
        <div className={styles.container}>
            <RxCross1 className={styles.exit}  onClick={()=>setIsRegisterPopUpVisible(false)}/>

            <h2 className={styles.loginTitle}>Sign up</h2>
            <form className={styles.loginForm} onSubmit={handleSubmit}>
                <div className={styles.inputGroup}>
                    <label className={styles.loginLable}>Email:</label>
                    <input value={email} onChange={handleEmailChange} className={styles.loginInput} type="text" id="email" name="email" />
                    {emailError && <span className={styles.errorMsg}>Invalid email</span>}
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.loginLable}>Password:</label>
                    <input value={password} onChange={handlePasswordChange} className={styles.loginInput} type="password" id="password" name="password" />
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.loginLable}>Repeat password:</label>
                    <input value={password2} onChange={handlePassword2Change} className={styles.loginInput} type="password" id="password" name="password" />
                    {(passwordError || passwordMatchError) &&
                        passwordError
                        ?<span className={styles.errorMsg}>Password can only contain letters, numbers and symbols, needs to be 8 characters long and include an uppercase letter</span>
                        :passwordMatchError &&<span className={styles.errorMsg}>Passwords do not match</span>
                    }
                </div>
                <div className={styles.buttons}>
                    <PurpleButton text="Connect Wallet" onClick={connectWallet} fontSize='13px'/>
                    <PurpleButton text="Sign up" type="submit"/>
                </div>

            </form>
        </div>
    );
}

export default RegisterPopUp;