import styles from './RatingBar.module.css';
import { setColorFromSecScore } from '../../utils/colorFromSecScore';
interface IRatingBar{
    rating:number,
    color1:string,
    color2:string
}


function numToRating(num:number){
    if(num<20) return "C"
    else if(num<30) return "CC"
    else if(num<40) return "CCC"
    else if(num<50) return "B"
    else if(num<60) return "BB"
    else if(num<70) return "BBB"
    else if(num<80) return "A"
    else if(num<90) return "AA"
    else return "AAA"

}

const RatingBar:React.FC<IRatingBar> = ({rating,color1,color2}) =>{
    return(
        <div className={styles.holder} style={{background:setColorFromSecScore(rating,color1,color2)}}>
        {numToRating(rating)}
        </div>
    )
}

export default RatingBar