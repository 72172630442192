import styles from './CodeComplexity.module.css';
import { codeComplexity,protocolData } from '../../../utils/reportDataTypes';
import { isNumber } from '../../../utils/adminUtils';
import BasicInput from '../../Reusable/BasicInput';
import BasicTextArea from '../../Reusable/BasicTextArea';
import BasicList from  '../../Reusable/BasicList';
interface ICodeComplexity{
    fullObj: protocolData,
    setOBJ: (newObj: any) => void;

}

const CodeComplexity: React.FC<ICodeComplexity> = ({ fullObj, setOBJ }) => {
    const obj:codeComplexity=fullObj.codeComplexity

    const handleAddProto=()=>{
        obj.similarProtocols.push({protocolName:"",similarityScore:0})
        setOBJ({...fullObj})
    }

    const handleSimilarChange =(i:number)=>(e: React.ChangeEvent<HTMLInputElement>)=> {
        obj.similarProtocols[i].protocolName=e.target.value;
        setOBJ({...fullObj})
    }

    const handleRemoveSimilar =(index:number)=>{
        delete obj.similarProtocols[index]
        setOBJ({...fullObj})
    }

    const handleLinesOfCodeChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        let num=Number(e.target.value)
        if(isNumber(num)){
            obj.linesOfCode=num
        }
        setOBJ({...fullObj})
    }

    const handleContractAmountChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        let num=Number(e.target.value)
        if(isNumber(num)){
            obj.amountOfContracts=num
        }
        setOBJ({...fullObj})
    }


    const handleIntegrationAmountChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        let num=Number(e.target.value)
        if(isNumber(num)){
            obj.amountOfExternalIntegrations=num
        }
        setOBJ({...fullObj})
    }

    const handleCodeStructureChange=(e: React.ChangeEvent<HTMLInputElement>)=>{
        obj.basicCodeStructure=e.target.value
        setOBJ({...fullObj})
    }

    const handleComplexCodeChange=(e: React.ChangeEvent<HTMLTextAreaElement>)=>{
        obj.mostComplexCodeParts=e.target.value
        setOBJ({...fullObj})
    }


    return(
    <div className={styles.container}>
        <span>Code complexity</span>


        <BasicInput title={"Lines of code"} value={obj.linesOfCode} placeholder='1105' onChange={handleLinesOfCodeChange} redBorder={false}/>
        <BasicInput title={"Amount of contracts"} value={obj.amountOfContracts} placeholder='3' onChange={handleContractAmountChange} redBorder={false}/>
        <BasicInput title={"Amount of external integrations"} value={obj.amountOfExternalIntegrations} placeholder='8' onChange={handleIntegrationAmountChange} redBorder={false}/>
        <BasicInput title={"Basic code structure"} value={obj.basicCodeStructure} placeholder='Code has no ..' onChange={handleCodeStructureChange} redBorder={false}/>
        <BasicTextArea title={"Most complex code parts"} value={obj.mostComplexCodeParts} placeholder='' onChange={handleComplexCodeChange} redBorder={false}/>

        <BasicList gap={10} title="Similar protocols" handleRemove={handleRemoveSimilar} handleAdd={handleAddProto} height={270} list={obj.similarProtocols}
           items={[{type:"input",property:"",onChange:handleSimilarChange,title:"",placeholder:"",borderIsValid:[] }]}/>


    </div>
    )
}

export default CodeComplexity
