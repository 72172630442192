import React, { useState } from 'react';
import styles from './BasicToolTip.module.css'

interface IBasicToolTip{
    icon:JSX.Element,
    text:JSX.Element,
}

const BasicToolTip:React.FC<IBasicToolTip>=({icon,text})=>{
    const [isHover,setIsHover]=useState(false);

    return(
        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            {icon}
            {isHover &&
                <div className={styles.lastBox}>
                    <div className={styles.triangle} />
                    <div className={styles.verifiedToolTip}>
                        {text}
                    </div>
                </div>
            }
        </div>
    )
}

export default BasicToolTip;