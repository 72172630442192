import styles from './Centralization.module.css';
import { protocolData } from '../../../utils/reportDataTypes';
import React from 'react';
import { BarChart } from './BarChart';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { centralizationDocsURL, otherDocsURL } from '../../../api';
import BasicToolTip from '../../Reusable/BasicToolTip';

interface ICentralization {
    protocolData: protocolData
}

const Centralization = React.forwardRef<HTMLDivElement, ICentralization>( ({ protocolData }, ref) => {

    const protocolsToCompareScores : number[] = [protocolData.centralization.centralizationScore,5,15];
    const protocolsToCompare : string[] = [protocolData.name,"Binance","Balancer"];
    
    const toolTipText = () => {
        return (<>The Decentralization Score is a value between 0 and 20 mesured by 4 metrics. < br/> Read more on <a rel="noreferrer" target="_blank" href={centralizationDocsURL}>docs</a></>);
    }
    const offChainMaintanceScoretoolTipText = () => {
        return (<>The Off Chain Maintance Score is a value between 1 and 3. < br/> Read more on <a rel="noreferrer" target="_blank" href={otherDocsURL}>docs</a></>);
    }

    return (
    <>
    <div className={styles.container} ref={ref}>
        <h2>Centralization</h2>
        <div className={styles.InnerContainer}>
            <div className={styles.InfoContainer}>
                <div className={styles.InfoContainerBox}>
                        <p className={styles.InfoContainerBoxText}>Decentralization score 
                        <BasicToolTip icon={<IoInformationCircleOutline size={20} />} text={toolTipText()}/>
                        </p>
                        <h2>{protocolData.centralization.centralizationScore} out of 20</h2>
                </div>
                <div className={styles.InfoContainerBox}>
                        <p>Contract Upgradability</p>
                        <h2>{protocolData.centralization.upgradable === true ? "Can be upgraded" : "No upgradability"}</h2>
                </div>
            </div>
            <div className={styles.chartContainer}>
                    <BarChart labels={protocolsToCompare} score={protocolsToCompareScores}/>
            </div>
        </div>
            
            <div className={styles.adminInfoContainer}>
                <h3> Admin / Governance functions</h3>
                <div className={styles.adminInfoContainerItems}>
                    <div className={styles.InfoContainerBoxReverse}>
                            <p>{protocolData.centralization.timelocksUsed === true ? "Uses Timelocks" : "No Timelocks"}</p>
                            <h3>{protocolData.centralization.timeLockInformation}</h3>
                    </div>
                    <div className={styles.InfoContainerBoxReverse}>
                            <p>{protocolData.centralization.pausable === true ? "Can be paused" : "No pause"}</p>
                            <h3>{protocolData.centralization.pausableInformation}</h3>
                    </div>
                    <div className={styles.InfoContainerBoxReverse}>
                            <p>Admin Wallet</p>
                            <h3>{protocolData.centralization.adminWallet}</h3>
                            <p style={{fontSize:"12px"}}>{protocolData.centralization.multiSigInformation}</p>
                    </div>
                </div>
                <p className={styles.adminInfoBoxText}><IoInformationCircleOutline size={20} /> {protocolData.centralization.adminInformation}</p>
            </div>
            <div className={styles.adminInfoContainer}>
                <h3> Offchain Information</h3>
                <div className={styles.adminInfoContainerItems}>
                    <div className={styles.InfoContainerBoxReverse}>
                            <p>Frontend</p>
                            <h3>{protocolData.centralization.frontendDeployment}</h3>
                    </div>
                    <div className={styles.InfoContainerBoxReverse}>
                            <p className={styles.InfoContainerBoxText}>
                            Offchain Maintance Score
                            <BasicToolTip icon={<IoInformationCircleOutline size={20} />} text={offChainMaintanceScoretoolTipText()}/>
                            </p>
                            <h3 className={styles.InfoContainerBoxReverseOffChainHeader}>
                            {protocolData.centralization.ownFrontendComplexityScore}.00
                            <div className={styles.OffchainMaintanceScoreGraph}>
                                <div className={styles.OffchainMaintanceScoreGraphItems}>
                                    <div style={
                                        {backgroundColor:"rgba(255, 99, 132, 0.2)",
                                        boxShadow: protocolData.centralization.ownFrontendComplexityScore === 3 ? "0px 0px 5px 1px rgba(255, 99, 132, 1)" : "none",
                                        WebkitBoxShadow: protocolData.centralization.ownFrontendComplexityScore === 3 ? "0px 0px 5px 1px rgba(255, 99, 132, 1)" : "none",
                                        borderColor:"rgba(255, 99, 132, 1)"}
                                        } className={styles.OffchainMaintanceScoreGraphItem}>
                                    <p>Poor</p>
                                    </div>
                                    <div style={
                                        {backgroundColor:"rgba(255, 206, 86, 0.2)",
                                        boxShadow: protocolData.centralization.ownFrontendComplexityScore === 2 ? "0px 0px 5px 1px rgba(255, 206, 86, 1)" : "none",
                                        WebkitBoxShadow: protocolData.centralization.ownFrontendComplexityScore === 2 ? "0px 0px 5px 1px rgba(255, 206, 86, 1)" : "none",
                                        borderColor:"rgba(255, 206, 86, 1)"}
                                        } className={styles.OffchainMaintanceScoreGraphItem}/>
                                    <div style={
                                        {backgroundColor:"rgba(75, 192, 192, 0.2)",
                                        boxShadow: protocolData.centralization.ownFrontendComplexityScore === 1 ? "0px 0px 5px 1px rgba(75, 192, 192, 1)" : "none",
                                        WebkitBoxShadow: protocolData.centralization.ownFrontendComplexityScore === 1 ? "0px 0px 5px 1px rgba(75, 192, 192, 1)" : "none",
                                        borderColor:"rgba(75, 192, 192, 1)"}
                                        } className={styles.OffchainMaintanceScoreGraphItem}>
                                    <p>Exelent</p>
                                    </div>
                                </div>
                            </div>
                            </h3>
                    </div>
                </div>
                <p className={styles.adminInfoBoxText}><IoInformationCircleOutline size={20} /> {protocolData.centralization.possiblityOfOwnFrontend}</p>
            </div>
    </div>
    </>
    );
})

export default Centralization;