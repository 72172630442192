import ReactFlow, { Controls } from 'reactflow';
import 'reactflow/dist/style.css';

const initialNodes = [
    {
      id: '1',
      data: { label: 'UniswapV3Pool.sol' },
      position: { x: 150, y: 0 },
    },
    {
      id: '2',
      data: { label: 'NoDelegateCall.sol' },
      position: { x: 0, y: 150 },
    },
    {
      id: '3',
      data: { label: 'IUniswapV3Pool.sol' },
      position: { x: 300, y: 150 },
    },
    {
        id: '4',
        data: { label: 'UniswapV3Factory.sol' },
        position: { x: -150, y: 0 },
      },
  ];

const initialEdges = [
    { id: 'e1-2', source: '1', target: '2' },
    { id: 'e1-3', source: '1', target: '3' },
    { id: 'e1-4', source: '4', target: '2' },
  ];

const proOptions = { hideAttribution: true };

const Flow = () => {

  return (
        <div style={{ height: '100%', width: '100%' }}>
        <ReactFlow nodes={initialNodes} edges={initialEdges} proOptions={proOptions} fitView={true}>
            <Controls />
        </ReactFlow>
    </div>    
  );
}

export default Flow;
