import styles from './CheckedInput.module.css'
interface ICheckedInput{
    title:string;
    checked:boolean;
    onChange:(e: React.ChangeEvent<HTMLInputElement>)=>void;
}

const CheckedInput:React.FC<ICheckedInput>=({title,checked,onChange})=>{
    return(
        <div className={styles.basicContainer}>
            <div>{title}</div>
            <input type="checkbox" checked={checked}  onChange={onChange}/>
        </div>
    )
}
export default CheckedInput