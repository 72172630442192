import { useState} from 'react'
import React from 'react'
import styles from './Login.module.css'

interface ILogin{
    setLoggedIn:(loggedIn:boolean)=>void
}
export const Login:React.FC<ILogin>=({setLoggedIn})=>{
    const [password,setPassword]=useState("")
    const [error,setError]=useState("")

    const handlePasswordChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPassword(e.target.value)
    }
    const handleLoginClick=()=>{
        if(password===process.env.REACT_APP_ADMIN_PASSWORD){
            setTimeout(()=>{
                setLoggedIn(true)
            },1000)
        }
        else{
            setTimeout(()=>{
                setError("Invalid password")
                setTimeout(()=>{
                    setError("")
                },2000)
            },1000)
        }
    }

    return(
        <div className={styles.container}>
            <div className={styles.loginContainer}>
                <div>Admin login</div>
                <input type="password" value={password} onChange={handlePasswordChange}/>
                <button onClick={()=>handleLoginClick()}>submit</button>
                {error && 
                    <div>{error}</div>
                }
            </div>
        </div>
    )
}