import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  export const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 1,
        color: 'white',
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        }
      },
      x: {
        grid: {
          display: false,
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: 'Decentralization Score Comparison',
      },
    },
  };
  
  //const labels = ['Data Validation', 'Undefined Behavior', 'Token related',];
  
  interface IBarChart {
    labels: string[],
    score: number[],
  }
  
  export const BarChart:React.FC<IBarChart> = ({labels,score}) => {
  
      const data = {
        labels,
          datasets: [
            {
              label: 'Score',
              data: score,
              borderColor: 'rgba(110, 94, 226, 1)',
              backgroundColor: 'rgba(110, 94, 226, 0.2)',
            },
          ],
        };
        
    return <Bar options={options} data={data} />;
  }