import { useState } from 'react'
import styles from './AdminPage.module.css'
import {BasicInfo} from '../../components/AdminPageComponents/BasicInfo'
import CodeComplexity from '../../components/AdminPageComponents/CodeComplexity'
import Centralization from '../../components/AdminPageComponents/Centralization'
import CrossProtocolExposure from '../../components/AdminPageComponents/CrossProtocolExposure'
import { protocolData } from '../../utils/reportDataTypes'
import { PastAudits } from '../../components/AdminPageComponents/PastAudits'
import { SecInfra } from '../../components/AdminPageComponents/SecInfra'
import {Login} from '../../components/AdminPageComponents/Login'
import axios from 'axios'
import { writeReportDataURL } from '../../api'
import { AddAuditCompany } from '../../components/AdminPageComponents/AddAuditCompany'
import { protocolDataCategory } from '../../utils/protocolDataCategoryTypes'

const AdminPage = () => {
    const [loggedIn,setLoggedIn]=useState(false)

    const [basicInfoIsValid,setBasicInfoIsValid]=useState(false)
    const [pastAuditsIsValid,setPastAuditsIsValid]=useState(false)
    const [centralizationIsValid,setCentralizationIsValid]=useState(true)
    const [crossProtocolExposure,setCrossProtocolExposure]=useState(true)
    const [secInfraIsValid,setSecInfraIsValid]=useState(false)


    const sendPublishReportDataRequest = async (request:protocolData) => {
        try {
          console.log(JSON.stringify(request));

          if(checkData()){
            const {data} = await axios.post(writeReportDataURL, 
                request,
                {
                  headers: {
                  'Content-Type': 'application/json',
                  }
                }
              );
              console.log(data.message);
              // Save return 
          }

        }catch(e){
          console.log(e);
          // Show error
          //setMessage({message: "No Connection to server"});
        }
    }

    const checkData=()=>{
        let checks=[basicInfoIsValid,pastAuditsIsValid,centralizationIsValid,crossProtocolExposure,secInfraIsValid]
        return !checks.includes(false)
    }
    

    const [protocolDataObj,setProtocolDataObj]=useState<protocolData>({
        name: "",
        socialLinks: [],
        score: 0,
        codeBaseLink: "",
        logoURL: "",
        category: protocolDataCategory.DEX,
        pastAudits: {auditAmount:0,reportAmount:0,reports:[],audits:[],lastCodeBaseModification:""},
        secInfra: {bugBounty: "",bugBountyAmount: 0,paidWhiteHatBefore: "",auditedBy: [],timeOfAudits: [],recentSecIncidents: []},
        codeComplexity: {linesOfCode:0,amountOfContracts:0,amountOfExternalIntegrations:0,basicCodeStructure:"",mostComplexCodeParts:"",similarProtocols:[],contractMetrics:[]},
        centralization: {centralizationScore:0,upgradable:false,timelocksUsed:false,timeLockInformation:"",multiSigInformation:"",adminWallet:"",adminInformation:"",pausable:false,pausableInformation:"",frontendDeployment:"",possiblityOfOwnFrontend:"",ownFrontendComplexityScore:0},
        crossProtocolExposure: {externalIntegrations:[],info:""},
        recommendations: {howToAvoidRisk:""}
    })


    if(loggedIn){
        return(
            <div className={styles.container} id="adminContainer"> 
                <BasicInfo fullObj={protocolDataObj} setOBJ={setProtocolDataObj} setBasicInfoIsValid={setBasicInfoIsValid}/>
                <PastAudits fullObj={protocolDataObj} setOBJ={setProtocolDataObj} setPastAuditsIsValid={setPastAuditsIsValid}/>
                <CodeComplexity fullObj={protocolDataObj} setOBJ={setProtocolDataObj}/>
                <CrossProtocolExposure fullObj={protocolDataObj} setOBJ={setProtocolDataObj} setCrossProtocolExposure={setCrossProtocolExposure}/>
                <Centralization fullObj={protocolDataObj} setOBJ={setProtocolDataObj} setCentralizationIsValid={setCentralizationIsValid}/>
                <SecInfra fullObj={protocolDataObj} setOBJ={setProtocolDataObj} setSecInfraIsValid={setSecInfraIsValid}/>

                <button className={styles.saveButton} onClick={() => sendPublishReportDataRequest(protocolDataObj)} >save</button>
                <AddAuditCompany />
            </div>
        )
    }
    else{
        return(
            <Login setLoggedIn={setLoggedIn}/>
        )
    }
}


export default AdminPage