
const darkTheme ={
    sides: "#1B242C",
    text:"#D6D6D6",
    main:"#191E2A",
    sideMenuFont:"#BABABD",
    footerColor:"#0F141A",
    sideMenuHover:"#15171e",
    dropDownMenuColor:"#262e40",
    gridColor: "rgba(167, 176, 184, 0.1)",
}

const lightTheme = {
  sides: "#E3EAF2",
  text:"#333333",
  main:"#FAFBFC",
  sideMenuFont:"#333333",
  footerColor:"#D6DFE4",
  sideMenuHover:"#cad9e7",
  dropDownMenuColor:"#ffffff",
  gridColor: "rgba(51, 67, 82,0.1)"
}

export { darkTheme, lightTheme };
