export function isValidURL(link: string): boolean {
    const urlPattern = /^(https?:\/\/)?(www\.)?([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}(:[0-9]+)?(\/\S*)?$/;
    return urlPattern.test(link);
}
export function isNumber(value:number) {
    return typeof value === 'number' && isFinite(value);
}

export const validBorder={border:"0.01px solid var(--font-color)"}
export const inValidBorder={border:"0.01px solid red"}

export function checkIfValid(array:Array<boolean | Array<boolean>>){
    return !array.flat().includes(false)
}