import styles from './PastAudits.module.css'
import { pastAudits,protocolData } from '../../../utils/reportDataTypes';
import {isValidURL,isNumber,checkIfValid} from  '../../../utils/adminUtils'
import { useState } from 'react';
import BasicInput from '../../Reusable/BasicInput';
import BasicList from '../../Reusable/BasicList';

interface IPastAudtis{
    fullObj:protocolData;
    setOBJ: (newObj: any) => void;
    setPastAuditsIsValid:(basicInfoIsValid:any)=>void;
}

export const PastAudits: React.FC<IPastAudtis> = ({ fullObj, setOBJ,setPastAuditsIsValid }) => {
    const obj:pastAudits=fullObj.pastAudits
    const [reportLinkIsValid, setReportLinkIsValid] = useState<Array<boolean>>([])

    const handleRemoveVul=(index:number)=>{
        delete reportLinkIsValid[index]
        setReportLinkIsValid(reportLinkIsValid)
        setPastAuditsIsValid(checkIfValid([reportLinkIsValid]))

        delete obj.reports[index]
        setOBJ({...fullObj})
    }

    const handleAddVul=()=>{
        setReportLinkIsValid(prev=>prev.concat([false]))
        setPastAuditsIsValid(false)
        obj.reports.push({issueType: "",link: "",severity: "",file: ""})
        setOBJ({...fullObj})
    }

    const handleAuditAmountChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        let copy={...fullObj}
        let num=Number(e.target.value)
        if(isNumber(num)){
            obj.auditAmount=num
        }
        setOBJ(copy)
    }

    const handleVulReportAmountChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        let copy={...fullObj}
        let num=Number(e.target.value)
        if(isNumber(num)){
            obj.reportAmount=num
        }
        setOBJ(copy)
    }

    const handleVulIssueTypeChange=(index:number)=>(e:React.ChangeEvent<HTMLInputElement>)=>{
        fullObj.pastAudits.reports[index].issueType=e.target.value
        setOBJ({...fullObj})
    }
    
    const handleVulLinkChange=(index:number)=>(e:React.ChangeEvent<HTMLInputElement>)=>{
        console.log(isValidURL(e.target.value))
        if(isValidURL(e.target.value)){
            reportLinkIsValid[index]=true
        }
        else{
            reportLinkIsValid[index]=false
        }
        setReportLinkIsValid([...reportLinkIsValid])
        setPastAuditsIsValid(checkIfValid([reportLinkIsValid]))

        fullObj.pastAudits.reports[index].link=e.target.value
        setOBJ({...fullObj})
    }

    const handleVulSeverityChange=(index:number)=>(e:React.ChangeEvent<HTMLInputElement>)=>{
        fullObj.pastAudits.reports[index].severity=e.target.value
        setOBJ({...fullObj})
    }

    const handleVulFileChange=(index:number)=>(e:React.ChangeEvent<HTMLInputElement>)=>{
        fullObj.pastAudits.reports[index].file=e.target.value
        setOBJ({...fullObj})
    }

    return(
    <div className={styles.container}>
        <span>Past audits</span>
        <BasicInput title={"Amount of audits"} value={obj.auditAmount}  placeholder='17' onChange={handleAuditAmountChange} redBorder={false}/>
        <BasicInput  title={"Amount of Vul reports"} value={obj.reportAmount}  placeholder='21' onChange={handleVulReportAmountChange} redBorder={false}/>

        <BasicList gap={40} title="Vul report" handleRemove={handleRemoveVul} handleAdd={handleAddVul} height={420} list={obj.reports}
           items={[
            {type:"input",property:"issueType",onChange:handleVulIssueTypeChange,title:"Type of issue",placeholder:"",borderIsValid:[] },
            {type:"input",property:"link",onChange:handleVulLinkChange,title:"Link",placeholder:"",borderIsValid:reportLinkIsValid },
            {type:"input",property:"Severity",onChange:handleVulSeverityChange,title:"Severity",placeholder:"",borderIsValid:[] },
            {type:"input",property:"file",onChange:handleVulFileChange,title:"File",placeholder:"",borderIsValid:[] }
            ]}/>

    </div>
    )
}

export default PastAudits