/*import styles from './CrossProtocolExposure.module.css'
import { useState } from 'react';
import { isNumber,checkIfValid } from '../../../utils/adminUtils'
import BasicList from '../../Reusable/BasicList';
*/
import { protocolData } from '../../../utils/reportDataTypes';
interface ICrossProtocolExposure{
    fullObj: protocolData,
    setOBJ: (newObj: any) => void;
    setCrossProtocolExposure:(crossProtocolExposure:boolean)=>void;
}

const CrossProtocolExposure: React.FC<ICrossProtocolExposure> = ({ fullObj, setOBJ, setCrossProtocolExposure }) => {
    //const obj:crossProtocolExposure=fullObj.crossProtocolExposure
    //const [scoreIsValid, setScoreIsValid] = useState<Array<boolean>>([])
    /*
    const handleAddEx = () => {
        obj.externalIntegrations.push("")
        obj.securityScoreOfIntegrations.push(0)
        scoreIsValid.push(true)
        setOBJ({...fullObj})
    };

    const handleAddFunc = () => {
        obj.functionsIncludingIntegrations.push("")
        setOBJ({...fullObj})
    };

    const handleRemoveEx = (i: number) => {
        delete scoreIsValid[i]
        setScoreIsValid([...scoreIsValid])
        setCrossProtocolExposure(checkIfValid(scoreIsValid))

        delete obj.externalIntegrations[i] 
        delete obj.securityScoreOfIntegrations[i]
        setOBJ({...fullObj})

    };

    const handleRemoveFunc = (i: number) => {
        delete obj.functionsIncludingIntegrations[i]
        setOBJ({...fullObj})
    };

    const handleFunctionChange=(index:number)=>(e: React.ChangeEvent<HTMLInputElement>)=>{
        obj.functionsIncludingIntegrations[index]=e.target.value
        setOBJ({...fullObj})

    }

    const handleExternalIntegrationChange=(index:number)=>(e: React.ChangeEvent<HTMLInputElement>)=>{
        obj.externalIntegrations[index]=e.target.value

        setOBJ({...fullObj})

    }

    const handleSecurityScoreOfIntegrationChange=(index:number)=>(e: React.ChangeEvent<HTMLInputElement>)=>{
        let num=Number(e.target.value)
        if(isNumber(num)){
            if(num>=0 && 100>=num){
                scoreIsValid[index]=true;
            }
            else{
                scoreIsValid[index]=false;
            }
            setScoreIsValid([...scoreIsValid])
            setCrossProtocolExposure(checkIfValid([scoreIsValid]))

            obj.securityScoreOfIntegrations[index]=num
            setOBJ({...fullObj})
        }
    }*/
    return(<>tmp</>);
    /*
    return(
    <div className={styles.container}>
        <span>Cross protocol exposure</span>

        <BasicList gap={10} title="Functions including intergation" handleRemove={handleRemoveFunc} handleAdd={handleAddFunc} height={200} list={obj.functionsIncludingIntegrations}
           items={[{type:"input",property:"",onChange:handleFunctionChange,title:"",placeholder:"",borderIsValid:[] }]}/>

        <BasicList gap={40} title="External integration" handleRemove={handleRemoveEx} handleAdd={handleAddEx} height={370} list={obj.externalIntegrations.map((n,i)=>{return {ex:n,score:obj.securityScoreOfIntegrations[i]}})}
           items={[{type:"input",property:"ex",onChange:handleExternalIntegrationChange,title:"External integration",placeholder:"",borderIsValid:[] },
           {type:"input",property:"score",onChange:handleSecurityScoreOfIntegrationChange,title:"Security score of integrations",placeholder:"",borderIsValid:scoreIsValid }
            ]
           }/>


    </div>
    )*/
}
export default CrossProtocolExposure