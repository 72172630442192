import { auditCompaniesType } from "./reportDataTypes"

export function getType(type:auditCompaniesType){
    const newType = type.toString()
    if(newType==="ContestPlatform"){
        return "Contest platform"
    }
    else if (newType==="IndependentResearcher"){
        return "Independent researcher"
    }
    else{
        return newType
    }
}