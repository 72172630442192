import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler
  } from 'chart.js';
  import { Radar } from 'react-chartjs-2';
  import {darkTheme} from '../../../../utils/theme';
import { protocolComparison } from '../../../../utils/reportDataTypes';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend,
    Filler,
  );
  
  interface IChart {
    currentTheme?: typeof darkTheme,
    comparisonData: protocolComparison[]
  }
  export const RadarChart:React.FC<IChart> = ({currentTheme,comparisonData}) => {
    
    const labels: string[] = [];
    comparisonData.map(protocol => labels.push(protocol.protocolName));
    const dataPoints: number[] = [];
    comparisonData.map(protocol => dataPoints.push(protocol.similarityScore));

    const data = {
        labels,
        datasets: [
          {
            label: 'Similarity',
            data: dataPoints,
            fill: true,
            backgroundColor: 'rgba(110, 94, 226, 0.2)',
            borderColor: 'rgba(110, 94, 226, 1)',
            borderWidth: 1,
          },
        ],
      };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom' as const,
          labels: {
            color: currentTheme?.text,
          }
        },
        title: {
          display: true,
          text: 'Similar Protocols',
          color: currentTheme?.text,
        },
      },
    
      scales: {
        r: {
          ticks: {
            display: false,
            stepSize: 20,
            
          },
          pointLabels: {
            color: currentTheme?.text,
          },
          grid : {
            color: currentTheme?.gridColor,
          },
          angleLines: {
            color: currentTheme?.gridColor,
          },
          suggestedMin: 0.1,
          suggestedMax: 1
        }
      }
    };
  
    return <Radar options={options} data={data} />;
  }