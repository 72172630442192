import styles from './SecInfra.module.css'
import { auditCompanies, protocolData } from '../../../utils/reportDataTypes';
import React, { useEffect, useState } from 'react'
import { tmpAuditCompanie, tmpBlockChains } from '../../../utils/templateProtocolData';
import axios from 'axios';
import { getCompanyByIdArrayURL } from '../../../api';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';

interface ISecInfra {
    protocolData: protocolData
}

const getBugBountyLink = (link : string) => {
    if(link.startsWith("https://immunefi.com/")){
                return <Link key={link} target='_blank' to={link}>Immunefi <FaExternalLinkAlt size={10}/></Link>
    }else if(link.length > 5){
        return <Link key={link} target='_blank' to={link}>Own <FaExternalLinkAlt size={10}/></Link>
    }else{
        return <p style={{color:"rgba(255, 99, 132, 1)"}}> No Bug Bounty</p>
    }
}

function formatNumberShort(num: number): string {
    if (num > 999999999) {
        return num.toLocaleString();
    } else if (num > 999999) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num > 999) {
        return (num / 1000).toFixed(1) + 'K';
    } else {
        return num.toString();
    }
}

const SecInfra = React.forwardRef<HTMLDivElement, ISecInfra>( ({ protocolData }, ref) => {
    const [auditCompanies,setAuditCompanies]=useState<auditCompanies[]>([tmpAuditCompanie])

    useEffect(() => {
        const fetchData = async () => {
            await sendGetAuditedByCompaniesRequest(protocolData.secInfra.auditedBy);
        }
        fetchData();
    },[protocolData.secInfra.auditedBy]);

    const sendGetAuditedByCompaniesRequest = async (id:number[]) => {
        try {
            const {data} = await axios.post(getCompanyByIdArrayURL, 
                id,
                {
                    headers: {
                    'Content-Type': 'application/json',
                    }
                }
            );
            const company : auditCompanies[] = data;
            if(company !== undefined){
                setAuditCompanies(company);
            }
        }catch(e){
        console.log(e);
        }
    }

    return (
    <>
    <div className={styles.container} ref={ref}>
        <h2>Sec. Infrastructure Overview</h2>
        <div className={styles.InnerContainer}>
            <div className={styles.InfoContainer}>
                <p className={styles.InfoContainerText}>Current Bug Bounty: {getBugBountyLink(protocolData.secInfra.bugBounty)}</p>
                <p className={styles.InfoContainerText}>Bug Bounty Max Payout: {formatNumberShort(protocolData.secInfra.bugBountyAmount)} $</p>
                <p className={styles.InfoContainerText}>Has paid White Hats before: {protocolData.secInfra.paidWhiteHatBefore}</p>
                <p className={styles.InfoContainerText}>Last Audit Date: {protocolData.pastAudits.audits[protocolData.pastAudits.audits.length-1].date}</p>
            </div>
            <div className={styles.NewsContainer}>
                <p>Recent Security Incidents</p>
                <ul className={styles.NewsView}>
                    <li className={styles.row}>
                        <p className={styles.text}>Incident</p>
                        <p className={styles.lostAmount}>Amount Lost</p>
                        <p className={styles.date}>Date</p>
                    </li>
                    {protocolData.secInfra.recentSecIncidents.map((item) => {
                         let lostAmount=item.lostAmount;
                        return (
                            <li key={item.title} className={styles.row}>
                                <a href={item.link} className={styles.text}>
                                    <FaTriangleExclamation className={styles.severityDisplay}/> 
                                    {item.title}
                                </a>
                                <p className={styles.lostAmount} style={(lostAmount === 0)?{color:"rgba(75, 192, 192, 1)"}:{color:"rgba(255, 99, 132, 1)"}}>{lostAmount === 0 ? "Insignificant" : lostAmount}</p>
                                <p className={styles.date}>{item.date}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
        <div className={styles.LowerRow}>
            <div className={styles.AuditCompanyLogoContainer}>
                    <h3>Secured by</h3>
                    <div className={styles.AuditCompanyList}>
                        {auditCompanies.map((item) => {
                            return (
                                <div key={item.name} className={styles.AuditCompany}>
                                    <img alt='' src={item.logoURL} className={styles.AuditCompanyLogo}/>
                                    <a rel="noreferrer" target='_blank' href={item.link}>{item.name} <br /> {item.type}</a>
                                </div>
                            )
                        })}
                    </div>
            </div>
            <div className={styles.DeployedChainsContainer}>
            <h3>Depoyed on</h3>
                        <div className={styles.AuditCompanyList}>
                        {tmpBlockChains.map((item) => {
                            return (
                                <div key={item.name} className={styles.AuditCompany}>
                                    <img alt='' src={item.logoURL} className={styles.AuditCompanyLogo}/>
                                    <p>{item.name} <br /> {item.info}</p>
                                </div>
                            )
                        })}
                        </div>
            </div>
        </div>
    </div>
    </>
    )
})

export default SecInfra;