import { useEffect,useState } from 'react';
import styles from './ForgotPassword.module.css'
import { useNavigate } from 'react-router-dom';
import BasicInput from '../../components/Reusable/BasicInput';


function containsUpperCase(str:string) {
    return /[A-Z]/.test(str);
  }

function isPasswordValid(inputString: string) {
    return !inputString.includes(" ") && inputString.length>7 && containsUpperCase(inputString)
}


const ForgotPassword=()=>{
    const [render,setRender]=useState(false)
    const [password,setPassword]=useState("")
    const [password2,setPassword2]=useState("")
    const [passwordError,setPasswordError]=useState(false)
    const [passwordMatchError,setPasswordMatchError]=useState(false)

    const handlePasswordChange=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPasswordError(!isPasswordValid(e.target.value))
        setPasswordMatchError(e.target.value!==password2)
        setPassword(e.target.value);
    }
    const handlePassword2Change=(e:React.ChangeEvent<HTMLInputElement>)=>{
        setPasswordMatchError(e.target.value!==password)
        setPassword2(e.target.value);
    }

    const navigate=useNavigate()

    async function tokenValidityCheck(token:string) {
        return "a";
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        //check token from the url, if it matches with a new account the account is confirmed and logged in
        if (token) {
            tokenValidityCheck(token)
            .then(res=>{
                if(res){
                    //set auth token if valid
                    //localStorage.setItem("authorization",res)
                    setRender(true);
                }
                else{
                    navigate("/");
                }
            })
            .catch(e=>{
                navigate("/")
            })

        }
        else{
            navigate("/");
        }
    }, [navigate])
    
    if(render){
        return(
            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <BasicInput value={password} title={"Set new password:"} placeholder='' redBorder={false} onChange={handlePasswordChange} type="password"/>
                    <BasicInput value={password2} title={"Repeat password:"} placeholder='' redBorder={false} onChange={handlePassword2Change} type="password"/>
                    <div className={styles.error}>{(passwordMatchError||passwordError) && 
                    passwordError
                        ?<span>Password can only contain letters, numbers and symbols, needs to be 8 characters long and include an uppercase letter</span>
                        : passwordMatchError && <span>Passwords do not match</span>}
                    </div>

                    <button>save</button>
                </div>
            </div>
        )
    }
    return(
        <div>
        </div>
    )
}

export default ForgotPassword