import { FaGithub, FaTwitter, FaYoutube } from "react-icons/fa"
import styles from './Footer.module.css';
import { DarkLightModeButton } from "../DarkLightModeButton";
import React from "react";

interface IFooter{
    isDark:boolean;
    setIsDark:(isDark:boolean)=>void
}


const Footer:React.FC<IFooter> = ({isDark,setIsDark}) => {
    return (
        <div className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.socialAndLightContainer}>
                    <div className={styles.socialLinks}>
                        <a href="https://twitter.com/SCAuditStudio">
                            <FaTwitter />
                        </a>
                        <a href="https://github.com/SCAuditStudio">
                            <FaGithub />
                        </a>
                        <a href="https://www.youtube.com/@SmartContractAuditStudio/videos">
                            <FaYoutube />
                        </a>
                    </div>
                    <DarkLightModeButton isDark={isDark} setIsDark={setIsDark} />
                </div>

                <div className={styles.infoLinks}>
                    <a href="https://scauditstudio.de/">Privacy Policy</a>
                    <a href="https://scauditstudio.de/">Imprint</a>
                </div>
                <div className={styles.copyrightInfo}>
                    <span>SCAS Oy 2024</span>
                    <span>All rights reserved.</span>
                </div>
            </div>
        </div>
    )
}

export default Footer