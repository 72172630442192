import React from 'react';
import styles from './BasicSelect.module.css'

interface IBasicSelect{
    curValue:string,
    onChange:(e:React.ChangeEvent<HTMLSelectElement>)=>void;
    options:Array<any>;
    value:string;
    display:string;
}

const BasicSelect:React.FC<IBasicSelect>=({curValue,onChange,options,value,display})=>{
    return(
        <div className={styles.container}>
            <select value={curValue} onChange={onChange}>
                {options.map((option, i) => {
                    return <option key={i} value={option[value]}>{option[display]}</option>;
                })}
            </select>
        </div>
    )
}

export default BasicSelect