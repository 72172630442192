export enum protocolDataCategory {
    AssetManagement,
    Services,
    Lending,
    Staking,
    Token,
    YieldAggregator,
    AMM,
    Derivatives,
    L2,
    L1,
    Stablecoin,
    Currency,
    Options,
    Perpetuals,
    DEX,
    Bridge,
    Launchpad,
    DAO,
    SyntheticAssets,
    Oracle,
    Bugbounty,
    Insurance,
    CDP,
    CrosschainLiquidity,
    NFTMarketplace,
    Wallet,
    LiquidStaking,
    ZeroKnowledgeProofs,
    Restaking,
    DEXAggregator,
    Gaming,
    LiquidRestaking,
    CEX,
    InsuranceAggregator,
    AccountAbstraction,
}