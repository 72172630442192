import styles from './InfoHeader.module.css';
import { protocolData } from "../../../utils/reportDataTypes";
import { useState,useEffect } from 'react';
import RatingBar from '../../RatingBar';
import { FaGithub, FaTwitter, FaYoutube,FaInstagram } from "react-icons/fa"
import { TbWorldWww } from "react-icons/tb";
import { Link } from 'react-router-dom';
import BasicToolTip from '../../Reusable/BasicToolTip';
import { ratingDocsURL } from '../../../api';
import { protocolDataCategory } from '../../../utils/protocolDataCategoryTypes';
import { setColorFromSecScore } from '../../../utils/colorFromSecScore';

interface IInfoHeader {
    protocolData:protocolData
}

const InfoHeader:React.FC<IInfoHeader> = ({protocolData}) => {

    const [scrollPosition, setScrollPosition] = useState<number>(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    const infoHeaderClass = scrollPosition > 100 ? styles.stickyTopBar : styles.topbar;

    
    return (
    <>
    <div className={infoHeaderClass}>
        <img className={styles.topbarLogo} src={protocolData.logoURL} alt=""/>
        <h2 className={styles.topbarTitle}>{protocolData.name}</h2>
        <h3 className={styles.topbarTitle}>Rating</h3>
        <div className={styles.ratingContainer}> 
        <BasicToolTip 
            icon={<RatingBar rating={protocolData.score} color1={"#FF6384"} color2={"#4BC0C0"}/>}
            text={
            <>
                <h2>SCAS Institutional Rating</h2><br />
                <p>The Scas institutional rating describes how secure a protocol is, it ranges form AAA to C, read more in <a rel="noreferrer" target="_blank" href={ratingDocsURL}>docs</a></p>
            </>}
        /> 
        </div>
        <h3 className={styles.topbarTitle}>Score<h3 style={{color:setColorFromSecScore(protocolData.score,"#FF6384","#4BC0C0")}}>{protocolData.score}.00</h3></h3>
        <div className={styles.category}>
            {protocolDataCategory[protocolData.category]}
        </div>
        <div className={styles.topbarSocialLinks}>{protocolData.socialLinks.map(link => {
            if(link.startsWith("https://instagram.com/")){
                return <Link key={link} target='_blank' to={link}><FaInstagram/></Link>
            }
            else if(link.startsWith("https://youtube.com/")){
                return <Link key={link} target='_blank' to={link}><FaYoutube/></Link>
            }   
            else if(link.startsWith("https://twitter.com/")){
                return <Link key={link} target='_blank' to={link}><FaTwitter/></Link>
            }
            else if(link.startsWith("https://github.com/")){
                return <Link key={link} target='_blank' to={link}><FaGithub/></Link>
            }
            else{
                return<Link key={link} target='_blank' to={link}> <TbWorldWww/></Link>
            }
            })}</div>
    </div>
    </>
    )
}

export default InfoHeader;