import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        //check token from the url, if it matches with a new account the account is confirmed and logged in
        if (token) {
            tokenValidityCheck(token)
            .then(res=>{
                if(res){
                    //set auth token if valid
                    //localStorage.setItem("authorization",res)
                    navigate("/join-beta");
                }
                else{
                    navigate("/");
                }
            })
            .catch(e=>{
                navigate("/")
            })

        }
        else{
            navigate("/");
        }
    }, [navigate])

    async function tokenValidityCheck(token:string) {
        return "";
    }

    return (
        <div>
            
        </div>
    );
};

export default RegisterPage;
