import { protocolDataCategory } from "./protocolDataCategoryTypes"
import { auditCompanies, auditCompaniesType, blockchain, centralization, codeComplexity, contractMetric, crossProtocolExposure, pastAuditReports, pastAudits, protocolComparison, protocolData, recentNews, recommendations, secInfra, vulReport } from "./reportDataTypes"

const tmpVulreports: vulReport[] = [
    {issueType:"Data Validation", link:"",severity:"medium",file:"Router.sol"},
    {issueType:"Token", link:"",severity:"high",file:"UniswapV3Pool.sol"},
    {issueType:"Token", link:"",severity:"medium",file:"Router.sol"},
    {issueType:"Data Validation", link:"",severity:"high",file:"UniswapV3Factory.sol"},
]
const tmpAuditreports: pastAuditReports[] = [
    {company:"Trail of bits", link:"",date:"03.2021",reportAmount:6},
    {company:"ABDK", link:"",date:"03.2021",reportAmount:2},
]

const tmpPastAudits : pastAudits = {
    auditAmount: 2,
    reportAmount: 8,
    lastCodeBaseModification: "03.2021",
    reports: tmpVulreports,
    audits: tmpAuditreports,
}

export const tmpAuditCompanie : auditCompanies = {
    name: "TrailsOfBits",
    link: "https://www.trailofbits.com/",
    logoURL: "/logo192.png",
    type: auditCompaniesType.Institutional,
    id: 7,
} 

const tmpRecentNews : recentNews = {
    title:"Hack of Founders Twitter account",
    link:"https://cointelegraph.com/news/hackers-compromise-uniswap-founder-twitter-account-promote-scam",
    lostAmount: 0,
    date: "July 2023"
}

const tmpSecInfra : secInfra = {
    bugBounty: "https://uniswap.org/bug-bounty",
    bugBountyAmount: 2200000,
    paidWhiteHatBefore: "Yes",
    auditedBy: [7,64],
    timeOfAudits: ["March 2021", "July 2021"],
    recentSecIncidents: [tmpRecentNews],
}

const tmpContractMetrics : contractMetric[] = [
    {fileName: "UniswapV3Factory.sol", linesOfCode: 430, nSLOC: 230, interfaces: 0, complexityScore: 14,inherits:["NoDelegateCall.sol"]},
    {fileName: "UniswapV3Pool.sol", linesOfCode: 890, nSLOC: 780, interfaces: 0, complexityScore: 30,inherits:["NoDelegateCall.sol"]},
    {fileName: "NoDelegateCall.sol", linesOfCode: 312, nSLOC: 200, interfaces: 0, complexityScore: 10,inherits:[]},
]

const tmpProtocolComparison : protocolComparison[] = [
    {protocolName:"SushiSwap",similarityScore: 80},
    {protocolName:"PancakeSwap",similarityScore: 70},
    {protocolName:"Compound",similarityScore: 30},
    {protocolName:"Curve",similarityScore: 60},
    {protocolName:"DodoSwap",similarityScore: 30},
    {protocolName:"MIMSwap",similarityScore: 30},
]

const tmpCodeComplexity : codeComplexity = {
    linesOfCode: 2580,
    amountOfContracts: 20,
    amountOfExternalIntegrations: 0,
    basicCodeStructure: "Uniswap V3 consists of 4 contracts, from which one the user interacts with only 2. In addition to this 16 libs",
    mostComplexCodeParts: "Uniswap includes an AMM to determine the price of tokens when swapping. This is the most crucial part of the system and also the most complex.",
    similarProtocols: tmpProtocolComparison,
    contractMetrics: tmpContractMetrics
}

const tmpCentralization : centralization = {
    centralizationScore: 10,
    upgradable: false,
    timelocksUsed: false,
    timeLockInformation: "No there are no timelocks needed",
    adminWallet: "0x",
    multiSigInformation: "",
    adminInformation: "There is only one function which is protocol fees",
    pausable: false,
    pausableInformation: "Contracts can not be paused",
    frontendDeployment: "Uniswap has own frontend",
    possiblityOfOwnFrontend: "Its easy to deploy own frontend",
    ownFrontendComplexityScore: 1,
}

const tmpCrossProtocolExposure : crossProtocolExposure = {
    externalIntegrations: [],
    info:"",
}

const tmpRecommendations : recommendations = {
    howToAvoidRisk: "The primary challenge when utilizing Uniswap arises from potential issues with malicious frontends. To mitigate this risk, consider developing an integration for frequent protocol usage."
}

export const tmpNewsData = [
    {date: "03.2021", protocol:"Uniswap",title:"Twitter hack of CEO in"},
    {date: "03.2022", protocol:"Uniswap",title:"Frequent phishing Scam Impersonating Protocol Team"},
    {date: "03.2023", protocol:"Uniswap",title:"Frontend hacked by bad actor"},
]

export const tmpBlockChains : blockchain[] = [
    {chainId: 0, name:"Ethereum", info:"Mainnet", logoURL:"https://scauditstudio.de/wp-content/uploads/2024/04/eth.png"},
    {chainId: 1001, name:"Polygon", info:"SideChain", logoURL:"https://polygontechnology.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F84d33469-d6ef-48b6-9824-9570fc245a5a%2FWhite_on_Gradient_Circle.png?id=d05c50cf-35fc-4a93-b645-c38a5210aa3c&table=block&spaceId=51562dc1-1dc5-4484-bf96-2aeac848ae2f&width=2000&userId=&cache=v2"},
]

export const tmpProtocolPrivArray = [
    {name:"Uniswap",score:80,logoURL:"https://img.cryptorank.io/exchanges/uniswap_v_3_base1697812078663.png",auditAmount:2},
    {name:"Notional",score:75,logoURL:"https://pbs.twimg.com/profile_images/1327058875627970561/zk8nf4kv_400x400.jpg",auditAmount:12},
    {name:"Optimism",score:50,logoURL:"https://pbs.twimg.com/profile_images/1734354549496836096/-laoU9C9_400x400.jpg",auditAmount:8},
    {name:"Sherlock",score:80,logoURL:"https://audits.sherlock.xyz/_next/static/media/sherlock_logo.bf519c9e.svg",auditAmount:2},
    {name:"Bitcoin",score:95,logoURL:"https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png",auditAmount:0},
    {name:"Arcadia",score:75,logoURL:"https://audits.sherlock.xyz/_next/image?url=https%3A%2F%2Fsherlock-files.ams3.digitaloceanspaces.com%2Fcontests%2Farcadia.jpg&w=256&q=75",auditAmount:6}
]

export const tmpProtocolReport:protocolData  = {
    name: "Uniswap V3",
    socialLinks: ["https://twitter.com/Uniswap","https://uniswap.org/","https://github.com/Uniswap/v3-core"],
    score: 80,
    codeBaseLink: "https://github.com/Uniswap/v3-core",
    logoURL: "https://img.cryptorank.io/exchanges/uniswap_v_3_base1697812078663.png",
    category: protocolDataCategory.DEX,
    pastAudits: tmpPastAudits,
    secInfra: tmpSecInfra,
    codeComplexity: tmpCodeComplexity,
    centralization: tmpCentralization,
    crossProtocolExposure: tmpCrossProtocolExposure,
    recommendations: tmpRecommendations
}