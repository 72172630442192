import styles from './CodeComplexity.module.css';
import { codeComplexity } from '../../../utils/reportDataTypes';
import React, { useState } from 'react';
import Flow from './FlowChart';
import { RadarChart } from './RadarChart';
import { darkTheme } from '../../../utils/theme';
import ShowMoreButton from '../../Reusable/ShowMoreButton';

interface ICodeComplexity {
    codeComplexity: codeComplexity,
    currentTheme?: typeof darkTheme,
}

const CodeComplexity=React.forwardRef<HTMLDivElement, ICodeComplexity>( ({ codeComplexity,currentTheme }, ref) => {
    const [showCodeMetrics,setShowCodeMetrics] = useState(false);
    const [showDependencyGraph,setShowDependencyGraph] = useState(false);
    
    return (
    <>
    <div className={styles.container} ref={ref}>
        <h2>Code Structure</h2>
        

        <div style={{display:"flex",flexDirection:"row"}}>
            <div className={styles.chartContainer}>
                <RadarChart currentTheme={currentTheme} comparisonData={codeComplexity.similarProtocols}/>
            </div>
            <div>
                <div className={styles.InfoContainer}>
                    <div className={styles.InfoContainerBox}>
                        <p>Lines of code</p>
                        <h2>{codeComplexity.linesOfCode}</h2>
                    </div>
                    <div className={styles.InfoContainerBox}>
                        <p>Amount of Contracts</p>
                        <h2>{codeComplexity.amountOfContracts}</h2>
                    </div>
                    <div className={styles.InfoContainerBox}>
                        <p>External Integrations</p>
                        <h2>{codeComplexity.amountOfExternalIntegrations}</h2>
                    </div>
                </div>
                <h3>Most complex code parts</h3>
                <p>{codeComplexity.mostComplexCodeParts}</p>
            </div>
        </div>
        <ShowMoreButton onClick={() => setShowDependencyGraph(!showDependencyGraph)} text={"Show Dependency Graph"} showMore={showDependencyGraph} />
        { showDependencyGraph &&
            <>
                <h3>Code Structure & Dependency </h3>
                <p>{codeComplexity.basicCodeStructure}</p>            
                <div className={styles.flowChartContainer}>
                    <Flow />
                </div>
            </>
        }
        <ShowMoreButton onClick={() => setShowCodeMetrics(!showCodeMetrics)} text={"Show Codefile metrics"} showMore={showCodeMetrics} />
        {codeComplexity.contractMetrics.length>0 && showCodeMetrics &&
                <div className={styles.vulReportContainer}>
                    <h3> Code Metrics </h3>
                    <ul className={styles.vulReportList}>
                        <li className={styles.row}>
                            <div className={styles.codeInfoFileName}> Contract Name </div>
                            <div className={styles.codeInfo}> Interfaces </div>
                            <div className={styles.codeInfo}> Lines </div>
                            <div className={styles.codeInfo}> nSLOC </div>
                            <div className={styles.codeInfo}> Complex. Score </div>
                        </li>
                        {codeComplexity.contractMetrics.map((contract,i)=>{
                            return( 
                                <li key={i} style={i!==codeComplexity.contractMetrics.length-1? {borderBottom:"1px solid var(--ProtocolPage-border-color)",display:"flex"}:{display:"flex"}}>
                                    
                                    <div className={styles.codeInfoFileName}>{contract.fileName}</div>
                                    <div className={styles.codeInfo}>{contract.interfaces}</div>
                                    <div className={styles.codeInfo}>{contract.linesOfCode}</div>
                                    <div className={styles.codeInfo}>{contract.nSLOC}</div>
                                    <div className={styles.codeInfo}>{contract.complexityScore}</div>
                                    
                                </li>
                                )
                        })}
                    </ul>
                </div>
        }
    </div>
    </>
    );
})

export default CodeComplexity;